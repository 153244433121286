export class ApplicationError extends Error {
  status: number;

  constructor(message: string, info?: { status?: number }) {
    super(message);
    this.status = info?.status || 500;
  }
}

export function handleErrorResponse(error: any) {
  if (error instanceof ApplicationError) {
    throw new Response(error.message, { status: error.status });
  }
  throw new Response(error instanceof Error ? error.message : "Unknown error");
}
