import { isRouteErrorResponse, useRouteError } from "@remix-run/react";

import { RouteErrorPage } from "../pages/route-error-page";

export function RouteErrorBoundary() {
  const error = useRouteError();
  // Only handle route errors
  if (!isRouteErrorResponse(error)) throw error;

  return <RouteErrorPage error={error} />;
}
